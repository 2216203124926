jQuery(document).ready(function($) {
    // Handle the add-to-cart form submission
    $('.add-to-cart-form').on('submit', function(e) {
        e.preventDefault();

        var $form = $(this);
        var $button = $form.find('button');
        var productId = $form.find('input[name="product_id"]').val();
        var quantity = $form.find('input[name="quantity"]').val();
        var variationId = $form.find('input[name="variation_id"]').val();
        var nonce = customAjax.nonce;

        $.ajax({
            type: 'POST',
            url: customAjax.ajaxurl,
            data: {
                action: 'add_product_to_cart',
                product_id: productId,
                quantity: quantity,
                variation_id: variationId,
                nonce: nonce
            },
            beforeSend: function(response) {
                $button.addClass('loading');
            },
            complete: function(response) {
                $button.removeClass('loading');
            },
            success: function(response) {
                if (!response.error) {
                    $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
                    if (typeof gtag === 'function') {
                        gtag('event', 'add_to_cart', {
                            'items': [{
                                'id': variationId ? variationId : productId,
                                'quantity': quantity
                            }]
                        });
                    } else if (typeof dataLayer !== 'undefined') {
                        dataLayer.push({
                            'event': 'add_to_cart',
                            'ecommerce': {
                                'items': [{
                                    'id': variationId ? variationId : productId,
                                    'quantity': quantity
                                }]
                            }
                        });
                    }
                } else {
                    console.log('Error:', response);
                }
                $('.cart-button').trigger('click');
                setTimeout(function () {
                    $('.flyout-close').trigger('click');
                }, 2000);
            },
            error: function(xhr, status, error) {
                console.log('AJAX Error:', xhr.responseText);
            }
        });
    });

    function updateCartFragments ( fragments ) {
        $.each(fragments, function(key, value) {
            $(key).replaceWith(value);
        });
        $('body').trigger('update_checkout');
    }

    // Update cart count when an item is added
    $(document.body).on('added_to_cart', function(event, fragments, cart_hash) {
        updateCartFragments( fragments );
    });

    // Update cart count when an item is removed
    $(document.body).on('removed_from_cart', function(event, fragments, cart_hash) {
        updateCartFragments( fragments );
    });

    $('body').on('change', '.qty', function() {
        var cart_item_key = $(this).data('cart_item_key');
        var quantity = $(this).val();

        $.ajax({
            type: 'POST',
            url: customAjax.ajaxurl,
            data: {
                action: 'update_mini_cart_quantity',
                cart_item_key: cart_item_key,
                quantity: quantity,
            },
            success: function(response) {
                if (response.fragments) {
                    $.each(response.fragments, function(key, value) {
                        $(key).replaceWith(value);
                    });
                }
            }
        });
    });

    $('body').on('click', '.remove_from_cart_button', function(e) {
        e.preventDefault();
        var $button = $(this);
        $button.addClass('loading');
    });

});
